export default {
    dev: {
        API_URI: 'http://localhost:8080',//'https://backoffice.pl-test.play-platform.com',
        RETAIL_SYSTEM_API_URL: 'https://backoffice.pl-test.play-platform.com/bo-retail/retail-service/',
        RETAIL_SYSTEM_CASHIER_API_URL: 'https://backoffice.pl-test.play-platform.com/bo-retail/retail-gateway/',
        BO_URL: 'https://backoffice.pl-test.play-platform.com',
        ENV: 'dev'
    },
    test: {
        API_URI: '/',
        RETAIL_SYSTEM_API_URL: '/bo-retail/retail-service/',
        RETAIL_SYSTEM_CASHIER_API_URL: '/bo-retail/retail-gateway/',
        BO_URL: 'https://backoffice.pl-test.play-platform.com',
        ENV: 'test'
    },
    stage1: {
        API_URI: '/',
        RETAIL_SYSTEM_API_URL: '/bo-retail/retail-service/',
        RETAIL_SYSTEM_CASHIER_API_URL: '/bo-retail/retail-gateway/',
        BO_URL: 'https://backoffice.pl-stage1.play-platform.com',
        ENV: 'stage1'
    },
    production: {
        API_URI: '/',
        RETAIL_SYSTEM_API_URL: '/bo-retail/retail-service/',
        RETAIL_SYSTEM_CASHIER_API_URL: '/bo-retail/retail-gateway/',
        BO_URL: 'https://eu.platform-bo.com',
        ENV: 'production',
    }
}
