import 'regenerator-runtime/runtime';
import config from './constants/envConfigRetail';

if (process.env.BUILD_MODE === 'development') {

    fetch('https://bo-route.pl-test.play-platform.com/?config=1', {
        method: 'GET',
        crossorigin: true,
    }).then((res) => res.json()).then(({apps}) => {

        window.appsVersionConfig = JSON.parse(apps);

        window.appsVersionConfig.root = "http://localhost:8080";

        window.deployEnv = 'test';
        window.GLOBAL_CONFIG = config.test;
        import('./main.js');
    });


} else {
    // fetch('/env.json')
    //     .then((res) => res.text())
    //     .then((res) => {
    //         console.log('res',res);
    //         const env = res.slice(8, -2);
    //         window.deployEnv = env || 'production';
    //         window.GLOBAL_CONFIG = config[env || 'production'];
    //         import('./main.js');
    //     });

    const noop = () => {
    };
    /* eslint-disable */
    const env = window.SYS_ENV;
    window.deployEnv = env || 'production';
    window.GLOBAL_CONFIG = config[env || 'production'];
    import('./main.js');
}

